<template>
  <div id="nav" class="container">
    <div class="row flex-nowrap" v-for="i in dat" >
      <div class="col" v-for="j in i">
        <div v-if="j.type==='html'">
          <div class="image rounded-3" >
          <span class="d-flex align-items-center" @click="goto(j.a)">
            <icon :name='j["title"]'/>
          </span>
          </div>
          <p class="text-center no-select">{{j["title"]}}</p>
        </div>
      </div>
    </div >
  </div>
</template>

<script>
import icon from "@/icon";

export default {
  name: "navbar",
  components:{
    icon
  },
  data() {
    return {
      dat: [
          [
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
          ],
          [
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
            {title:"bilibili",type:"html",a:"https://www.bilibili.com/"},
          ]
        ]
      }
  },
  methods: {
    goto(url){
      window.location.href=url
    }
  }
}
</script>

<style scoped>
#nav {
  position: absolute;  /* 或使用 `fixed` 以相对于视口定位 */
  top: 40vh;
  left: 50%;            /* 将元素的左边缘设置在视口宽度的50%的位置 */
  transform: translateX(-50%); /* 将元素向左移动自身宽度的50%，以达到完全居中的效果 */
  width: 40%;           /* 可以设置一个宽度，根据需要调整 */
}

  .image {
    position: relative;        /* 设置相对定位，为内部元素的居中提供基准 */
    width: 100%;               /* 使 .image 元素的宽度填充其容器 */
    padding-top: 100%;         /* top padding为宽度的100%，这使得 .image 保持正方形 */
    overflow: hidden;          /* 防止内容溢出 */
    background-color: rgba(255, 255, 255, 0.4);
  }

  .image > span {
    position: absolute;        /* 使用绝对定位使 span 居中 */
    top: 50%;                  /* 将 top 设置为50%将元素的顶部对齐容器的中心 */
    left: 50%;                 /* 将 left 设置为50%将元素的左边对齐容器的中心 */
    transform: translate(-50%, -50%); /* 使用 transform 偏移来准确地居中元素 */
    width: 100%;               /* 如果需要, 你可以设置具体宽度 */
    display: flex;             /* 启用 flexbox */
    align-items: center;       /* 垂直居中 */
    justify-content: center;   /* 水平居中 */
  }
</style>