<template>
    <div class="main" @focusin="select_search()" @focusout="not_select_search">

        <div
            class="input-group rounded-5 shadow-sm search_div d-flex justify-content-center align-items-center border-0 position-relative"
            :class="{'no_select': !select_status,'selected':select_status}"
            v-on:keydown.enter="enter">
            <span v-if="engine_switching" class="engine_switch_text">{{ 'test' }}</span>
            <transition>
                <div v-if="select_status" class="input-group-append text-center">
                    <div class="btn-group" role="group">
                        <button class="btn rounded-circle dropdown-toggle engine_logo"
                            aria-expanded="false"
                            data-bs-toggle="dropdown"
                            type="button"
                            v-html="engine_logo">
                        </button>
                        <ul class="dropdown-menu engine_select">
                            <li v-for="(i,key) in engines">
                                <button @click="set_search_engine(key)"
                                        class="dropdown-item rounded-2 d-flex align-items-center"
                                        @mousedown.prevent="$refs.search_input.focus">
                                    <span v-html="i[1]" class="d-flex align-items-center"></span>
                                    {{ i[2] }}
                                </button>

                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
            <input
                ref="search_input"
                type="text"
                class="form-control text-center search_input"
                v-model="search_text">
            <transition>
                <div v-if="select_status" class="input-group-append">
                    <button
                        class="btn rounded-circle text-center enter_button"
                        @click="enter"
                        type="button">
                        <box-icon name='search'>
                        </box-icon>
                    </button>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            select_status: false,
            engine_url: "",
            engine_switching: false,
            search_text: "",
            engine_logo: "",
            engines: {
                baidu: ["https://www.baidu.com/s?ie=utf-8&word=", "<box-icon type='logo' name='baidu' ></box-icon>", "百度(Baidu)"],
                google: ["https://www.google.com/search?q=", "<box-icon type='logo' name='google' ></box-icon>", "谷歌(Google)"],
                bing: ["https://www.bing.com/search?q=", "<box-icon type='logo' name='bing' ></box-icon>", "必应(Bing)"],
            }
        }
    },
    mounted() {
        this.set_search_engine(localStorage.getItem('default_engine') || 'baidu')
        this.$refs.search_input.placeholder = "搜索"
    },

    name: "search",

    methods: {
        set_search_engine(engine) {
            localStorage.setItem('default_engine', engine)
            this.engine_url = this.engines[engine][0]
            this.engine_logo = this.engines[engine][1]
        },

        not_select_search(event) {
            if (event.relatedTarget === null || !event.currentTarget.contains(event.relatedTarget)) {
                this.select_status = false;
                this.$refs.search_input.placeholder = "搜索"
                this.search_text = ""
            }
        },
        select_search() {
            this.select_status = true
            this.$refs.search_input.placeholder = ""
        },
        enter() {
            window.location.href = this.engine_url + this.search_text
        }
    }
}
</script>

<style scoped>
.main {
    display: flex;
    justify-content: center;
}

.engine_logo, .enter_button {
    height: 100%;
    width: 40px;
    display: flex;
    justify-content: center;
    padding: 5px;
    align-items: center;
    border: none !important;
    border-radius: 50%;
}

/*搜索栏左侧的logo的样式*/
.engine_logo {
    margin-left: 5px
}

.enter_button {
    margin-right: 5px
}

.search_input {
    border: none;
    background-color: rgba(0, 0, 0, 0) !important;
}

/*搜索文字框的样式*/

.no_select, .selected {
    /*position: absolute;*/
    height: 45px;
    transition: .3s ease;
    backdrop-filter: blur(10px);
}

/*主题的公共样式代码*/

.no_select {
    width: 15%;
    background-color: rgba(255, 255, 255, 0.35);
}

/*未选中时样式*/

.no_select:hover, .selected {
    width: 40%;
    background-color: rgba(255, 255, 255, 0.4);
}

.selected {
    background-color: rgba(255, 255, 255, 0.6);
}


*:focus {
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

/*去掉bootstrap特有的选中后的蓝色框*/
.v-enter-from, .v-leave-to {
    opacity: 0;
}

/*vue渐变*/
.v-enter-active, .v-leave-active {
    transition: opacity .3s;
}

/*vue渐变*/
.dropdown-toggle::after {
    display: none;
}

/*去掉bootstrap下拉按钮的向下小箭头*/
.engine_select {
    margin-top: 3px !important;
    padding: 5px;
    background-color: rgba(255, 255, 255, 0.5);
}

/*下拉菜单样式*/
.engine_select > li {
}

.engine_select > li > button {
}

/*下拉菜单项目按钮的样式*/
.engine_switch_text {
    position: absolute;
    top: 50%; /* 距离包含块顶部10px */
    left: 8%; /* 距离包含块左侧10px */
    z-index: 1000;
    transform: translateY(-50%);
    user-select: none
}

/*下拉菜单项目按钮内的文字的样式*/
.engine_select > li > button:hover {
    background-color: rgba(222, 222, 222, 0.9) !important;
}
</style>
