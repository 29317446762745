<template>
  <div id="timeText" class="text-center text-white fs-1 user-select-none"><span>{{ currentTime }}</span></div>
</template>

<script>
export default {
  name: "_time",
  data() {
    return {
      currentTime: this.getCurrentTime()
    };
  },
  mounted() {
    this.timer = setInterval(() => {
      this.currentTime = this.getCurrentTime();
    }, 1000);
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
  methods: {
    getCurrentTime() {
      const now = new Date();
      return now.getHours() + ':' + now.getMinutes().toString().padStart(2, '0');
    }
  }
}
</script>

<style scoped>
#timeText {
  min-width: 100%;
  max-width: 300px;
  text-shadow: 0 0 20px rgba(0,0,0,.35);
  animation: delayedFadeIn 1s;
}
#timeText>span {
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
  transition: .25s;
}
#timeText>span:hover {
  display: inline-block;
  transform: scale(110%); /* 鼠标悬停时放大10% */
}
@keyframes delayedFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}
</style>
