<template>
<!--    <img src="https://images.elysia.ink/i/2024/04/16/47b408ac-5ad7-0e15-220e-6c22dd7fa149.png" id="background" alt="" style="">-->
  <video src="https://images.elysia.ink/i/2024/04/16/05c30244-c3e7-698e-0a7d-15479cee6fa9.mp4" id="background" autoplay muted loop/>
<!--  <video src="elysia_background.mp4" id="background" autoplay muted loop/>-->
    <div>
      <Time/>
      <search/>
<!--      <navbar/>-->
    </div>
    <icp/>
</template>

<script>

import Search from "@/components/search";
import icp from "@/components/icp";
import Time from "@/components/time";
import Navbar from "@/components/navbar";

export default {
  name: 'App',
  components: {
    Navbar,
    Time,
    icp,
    Search
  }
}
</script>

<style scoped>
#background{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* 宽度覆盖整个容器 */height: 100%;
  /* 高度覆盖整个容器 */
  object-fit: cover;
  z-index: -1;
}

</style>
