<template>
  <div class="icp no-select">
    <span id="nnn" class="rounded-2">
      {{ `&copy ${year} ${author} ` }}|
      <a href="http://beian.miit.gov.cn/" target="_blank">{{ record }}</a>
    </span>
  </div>
</template>

<script>
export default {
  name: "icp",
  data(){
    return {
      year : new Date().getFullYear(),        // 一般都是最新的一年
      author : 'alsoeasy',					          // 作者名
      record : '冀ICP备2023010944号',	  		// 备案号
    }
  }
}

</script>

<style>
.icp {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 36px;
  white-space: pre;
  text-align: center;
  color: gray;
  z-index: 1000;
}
.icp > span{
  display: inline-block;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}
.icp >span> a {color: gray;text-decoration: none;}
.icp >span> a:hover {color: aqua;}
</style>
